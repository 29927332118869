import React from "react";

// @material-ui icons

import ForumIcon from '@material-ui/icons/Forum';
import CachedIcon from '@material-ui/icons/Cached';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";
import { Grid, Box } from "@material-ui/core";

import iphone from "assets/img/sections/circle3.png";

import Extension from "@material-ui/icons/Extension";



import consultingPic from "assets/img/examples/card-profile5.jpg";
import technologyPic from "assets/img/update1/web5.jpg";

// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import pricingStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/pricingStyle.js";

import office2 from "assets/img/examples/office2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/examples/card-project2.jpg";
import cardProject3 from "assets/img/examples/card-project3.jpg";
import cardProject4 from "assets/img/examples/card-project4.jpg";
import cardProject5 from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import iphone2 from "assets/img/sections/iphone2.png";

import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import DevicesIcon from '@material-ui/icons/Devices';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';


import services2 from "assets/img/test/services3.png";
import api3 from "assets/img/test/api4.png";
import ui1 from "assets/img/test/ui1.png";

import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";


const useStyles = makeStyles(projectsStyle);
const useFeaturesStyles = makeStyles(featuresStyle);
const usePricingStyles = makeStyles(pricingStyle);

export default function ServicesDescription({ ...rest }) {
  const classes = useStyles();
  const featuresClasses = useFeaturesStyles();
  const pricingClasses = usePricingStyles();
  return (
        <div className="cd-section" {...rest}>
          <div className={pricingClasses.pricing}>
            <div className={classes.container}>

              <GridContainer>
                <GridItem
                  xs={12}
                  sm={8}
                  md={8}
                  className={
                    classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                  }
                >
                  <h1 className={classes.title}>
                    Our Services
                  </h1>
                  <h5 className={classes.description}>
                    Tap into our niche expertise in designing end-to-end financial guidance solutions for end-consumers and financial advisors.
                  </h5>
                  <div className={classes.sectionSpace} />
                </GridItem>
              </GridContainer>
              <GridContainer id="consulting">
                <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                  <InfoArea
                    className={classes.info4}
                    title="Consulting Services"
                    description="We can help with creating a holistic overview of your financial guidance technology, or target specific areas for in-depth analysis."
                    icon={RateReviewIcon}
                    iconColor="rose"
                  />
                  <br />
                  <InfoArea
                    className={classes.info4}
                    title="Solution Architecture"
                    description="We can assist with defining solution architecture for your financial advice/guidance solutions, following the industry's best practices."
                    icon={SettingsApplicationsIcon}
                    iconColor="success"
                  />
                  <br />
                  <InfoArea
                    className={classes.info4}
                    title="AI Technology"
                    description="Our team can help to outline technical solutions for your AI-related projects, with a wide range of business use-cases and speicif problems."
                    icon={ViewCarousel}
                    iconColor="info"
                  />
                </GridItem>
                <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                  <Card
                    plain
                    className={classes.card2}
                  >
                    <CardBody plain>
                      <img src={services2} style={{ maxHeight: 400 }} alt="..." />
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
  );
}
