/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionDescription from "./Sections/SectionDescription.js";
import SectionCards from "./Sections/SectionCards.js";
import SectionTeam from "./Sections/SectionTeam.js";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import SectionFeatures from "./Sections/SectionFeatures";
import SectionContacts from "./Sections/SectionContacts";
import SectionCollaboration from "./Sections/SectionCollaboration";
import TechnologyDescription from "./Sections/TechnologyDescription";
import ServicesDescription from "./Sections/ServicesDescription";
import SectionBlogs from "./Sections/SectionBlogs";
import SectionFooter from "./Sections/SectionFooter";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        var additionalOffset = target != "contact" ? 500 : 0;
        scrollGo(document.documentElement, targetScroll.offsetTop + additionalOffset, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  return (
    <div>
      <Header
        brand="PlanLogix"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "info"
        }}
      />
      <Parallax
        image={require("assets/img/card-project1.jpg")}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h2>
                  Welcome to PlanLogix
                </h2>
                <h4>
                  We help other companies to streamline and scale up the delivery of their financial advice to consumers
                </h4>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <ServicesDescription id="services" />
        <SectionFeatures id="capabilities" />
        <SectionBlogs id="blog-latest" />
        <SectionTeam id="team" />
        <SectionContacts id="contact" />
      </div>
      <SectionFooter />
    </div>
  );
}
